import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Button from "../components/Button"
import Seo from "../components/Seo"

import { container, blogPostContent } from "./blogPostTemplate.module.css"

export const query = graphql`
  query getPost($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        date(formatString: "YYYY-MM-DD")
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          relativePath
        }
      }
      html
    }
  }
`;

const BlogPostTemplate = ({ data }) => {
  const {
    frontmatter: {
      title,
      description,
      date,
      image,
    },
    html
  } = data.markdownRemark;

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={`/images/${image.relativePath}`}
        isArticle={true}
      />
      <div className={`grid grid-cols-6 py-6 ${container}`}>
        <section className="col-span-6 md:col-start-2 md:col-span-4 px-5 md:p-0">
          <h1 className="pb-2">{title}</h1>
          <small>Pubblicato il {date} da Francesco Latini</small>
          <article className="py-4">
            <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt="cover-articolo" />
          </article>
          <article dangerouslySetInnerHTML={{ __html: html }} className={`select-none ${blogPostContent}`} />
          <article className="text-center py-6">
            <Button to="/articoli" text="Torna agli articoli" />
          </article>
        </section>
      </div>
    </Layout>
  )
};

export default BlogPostTemplate
